import { useContext } from 'react';

import { SideMenuContext } from '@modules/SideMenu/context/SideMenu.context';

export const withSideMenuContext = (Component) => {
	return function WrappedComponent(props) {
		const sideMenuContext = useContext(SideMenuContext);

		return <Component {...props} sideMenuContext={sideMenuContext} />;
	};
};
